<div class="m-t-12 row">
  <div class="col-3">
    <app-insert-user-data-stepper
      [step]="step"
      [selectedDate]="dataForm.controls.dateForm.controls.selectedDate.value"
      [steps]="dataForm.controls.stepsForm.controls.steps.value"
      [trained]="dataForm.controls.trainingForm.controls.trained.value"
      [weight]="dataForm.controls.weightForm.controls.weight.value"
      [cheatDay]="dataForm.controls.nutritionForm.controls.cheatDay.value"
      [calories]="dataForm.controls.nutritionForm.controls.calories.value"
      [carbohydrates]="dataForm.controls.nutritionForm.controls.carbohydrates.value"
      [proteins]="dataForm.controls.nutritionForm.controls.proteins.value"
      [fats]="dataForm.controls.nutritionForm.controls.fats.value"
      [dataForm]="dataForm"
      [loading]="loadingDatesWithData$ | async"
      (next)="step = step + 1"
      (back)="step = step - 1"
      (save)="save()"
    ></app-insert-user-data-stepper>
  </div>
  <div class="col-2"></div>
  <div class="col-7">
    <div class="row w-60">
      <div class="col">
        @if (step === 0) {
          <h2>Scegli il giorno</h2>
          <p>Indica il giorno per il quale vuoi inserire i dati</p>
          <form [formGroup]="dataForm.controls.dateForm" class="m-t-24">
            @if ((this.loadingDatesWithData$ | async) === false) {
              <mat-card style="width: 250px">
                <mat-calendar
                  #calendar
                  [dateClass]="dateClass"
                  [selected]="dataForm.controls.dateForm.controls.selectedDate"
                  (selectedChange)="updateFormDate($event)"
                  [dateFilter]="dateCanBeSelected"
                ></mat-calendar>
              </mat-card>
            } @else {
              <mat-spinner></mat-spinner>
            }
          </form>
        }
        @if (step === 1) {
          <h2>Quanti passi hai fatto?</h2>
          <p>Indica il numero di passi fatti nel giorno indicato</p>
          <form [formGroup]="dataForm.controls.stepsForm" class="m-t-24">
            <mat-form-field appearance="outline">
              <mat-label>Passi</mat-label>
              <input formControlName="steps" min="0" matInput type="number" />
            </mat-form-field>
          </form>
        }
        @if (step === 2) {
          <h2>Ti sei allenato/a?</h2>
          <p>Indica se ti sei allenato/a nel giorno indicato</p>
          <form [formGroup]="dataForm.controls.trainingForm" class="m-t-24">
            <mat-checkbox class="m-b-24" formControlName="trained">Ti sei allenato/a?</mat-checkbox>
          </form>
        }
        @if (step === 3) {
          <h2>Quale è il tuo peso?</h2>
          <p>Indica il peso che avevi nel giorno indicato</p>
          <form [formGroup]="dataForm.controls.weightForm" class="m-t-24">
            <mat-form-field appearance="outline">
              <mat-label>Peso</mat-label>
              <input formControlName="weight" min="0" matInput type="number" />
            </mat-form-field>
          </form>
        }
        @if (step === 4) {
          <h2>Quanto hai mangiato?</h2>
          <p>Inserisci i macronutrienti e le calorie che hai mangiato nel giorno indicato</p>
          <form [formGroup]="dataForm.controls.nutritionForm" class="m-t-24">
            <mat-checkbox class="m-b-24" formControlName="cheatDay" #cheatDay>Hai sgarrato?</mat-checkbox>
            @if (!dataForm.controls.nutritionForm.controls.cheatDay.value) {
              <mat-form-field appearance="outline">
                <mat-label>Carboidrati</mat-label>
                <input formControlName="carbohydrates" min="0" matInput type="number" />
              </mat-form-field>
              <br />
              <mat-form-field appearance="outline">
                <mat-label>Proteine</mat-label>
                <input formControlName="proteins" min="0" matInput type="number" />
              </mat-form-field>
              <br />
              <mat-form-field appearance="outline">
                <mat-label>Grassi</mat-label>
                <input formControlName="fats" min="0" matInput type="number" />
              </mat-form-field>
              <br />
              <mat-form-field appearance="outline">
                <mat-label>Calorie</mat-label>
                <input formControlName="calories" min="0" matInput type="number" />
              </mat-form-field>
            }
          </form>
        }
      </div>
    </div>
  </div>
</div>
