import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DailyUserData, UserData, AdditionalUserInfo } from './user-data.model';

export const UserDataActions = createActionGroup({
  source: 'UserData',
  events: {
    save: props<{ data: DailyUserData }>(),
    saveSuccess: emptyProps(),
    saveError: props<{ error: Error }>(),
    loadDatesWithData: props<{ year: number; month: number }>(),
    loadDatesWithDataSuccess: props<{ dates: string[] }>(),
    loadDatesWithDataError: props<{ error: Error }>(),
    loadDataByDate: props<{ date: Date }>(),
    loadDataByDateSuccess: props<{ data: DailyUserData }>(),
    loadDataByDateError: props<{ error: Error }>(),
    loadUserData: emptyProps(),
    loadUserDataSuccess: props<{ userData: UserData }>(),
    loadUserDataError: props<{ error: Error }>(),
    getAdditionalUserInfo: emptyProps(),
    getAdditionalUserInfoSuccess: props<{ additionalUserInfo: AdditionalUserInfo }>(),
    getAdditionalUserInfoError: props<{ error: Error }>(),
    addAdditionalUserInfo: props<{ height: number; birthday: Date }>(),
    addAdditionalUserInfoSuccess: props<{ additionalUserInfo: AdditionalUserInfo }>(),
    addAdditionalUserInfoError: props<{ error: Error }>()
  }
});
