import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-insert-user-data-stepper',
  templateUrl: './insert-user-data-stepper.component.html',
  styleUrl: './insert-user-data-stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertUserDataStepperComponent {
  @Input({ required: true }) loading: boolean;

  @Input({ required: true }) dataForm: FormGroup;

  @Input({ required: true }) step: number;

  @Input({ required: true }) selectedDate: Date;
  @Input({ required: true }) steps: number;
  @Input({ required: true }) trained: boolean;
  @Input({ required: true }) weight: number;

  @Input({ required: true }) cheatDay: boolean;
  @Input({ required: true }) calories: number;
  @Input({ required: true }) carbohydrates: number;
  @Input({ required: true }) proteins: number;
  @Input({ required: true }) fats: number;

  @Output() save = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  formSteps = [
    {
      label: 'Giorno',
      formGroupName: 'dateForm'
    },
    {
      label: 'Passi',
      formGroupName: 'stepsForm'
    },
    {
      label: 'Allenamento',
      formGroupName: 'trainingForm'
    },
    {
      label: 'Peso',
      formGroupName: 'weightForm'
    },
    {
      label: 'Nutrizione',
      formGroupName: 'nutritionForm'
    }
  ];

  getFormGroup(name: string) {
    return this.dataForm.get(name) as FormGroup;
  }

  canGoNext() {
    if (this.loading) {
      return false;
    }

    for (let i = 0; i <= this.step; i++) {
      const step = this.formSteps[i];

      if (!this.getFormGroup(step.formGroupName).valid) {
        return false;
      }
    }
    return true;
  }

  getStepValueLabel(name: string) {
    switch (name) {
      case 'dateForm': {
        if (this.selectedDate) {
          return this.selectedDate.toLocaleDateString();
        }
        return '-';
      }

      case 'stepsForm': {
        if (this.steps) {
          return this.steps;
        }
        return '-';
      }

      case 'trainingForm': {
        return this.trained ? 'Si' : '-';
      }

      case 'weightForm': {
        if (this.weight) {
          return this.weight;
        }
        return '-';
      }

      case 'nutritionForm': {
        if (this.getFormGroup('nutritionForm').valid) {
          if (this.cheatDay) {
            return 'Sgarro';
          }

          return `${this.carbohydrates}C - ${this.proteins}P - ${this.fats}F`;
        }
        return '-';
      }

      default:
        break;
    }
  }
}
