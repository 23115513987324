import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DailyUserData, UserData, AdditionalUserInfo } from './user-data.model';
import { format } from 'src/app/core/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class UserDataDataService {
  constructor(private http: HttpClient) {}

  save(data: DailyUserData) {
    return this.http.post<void>('/api/data', data);
  }

  loadDatesWithData(year: number, month: number) {
    return this.http.get<string[]>(`/api/data/count/?year=${year}&month=${month}`);
  }

  loadDataByDate(date: Date) {
    return this.http.get<DailyUserData>(`/api/data/${format(date)}`);
  }

  loadUserData() {
    return this.http.get<UserData>('/api/data');
  }

  getAdditionalUserInfo() {
    return this.http.get<AdditionalUserInfo>('/api/users');
  }

  addAdditionalUserInfo(height: number, birthday: Date) {
    return this.http.post<AdditionalUserInfo>('/api/users/', {
      height: height,
      birthday: format(birthday)
    });
  }
}
