import { Injectable } from '@angular/core';
import { UserDataActions } from './user-data.actions';
import { Store } from '@ngrx/store';
import { AdditionalUserInfo, DailyUserData, UserData } from './user-data.model';
import { Observable, tap } from 'rxjs';
import {
  selectAdditionalUserInfo,
  selectAdditionalUserInfoLoaded,
  selectDataByDate,
  selectDatesWithData,
  selectLoadingDataByDate,
  selectLoadingDatesWithData,
  selectLoadingUserData,
  selectUserData
} from './user-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  readonly datesWithData$: Observable<Date[]> = this.store.select(selectDatesWithData);
  readonly loadingDatesWithData$: Observable<boolean> = this.store.select(selectLoadingDatesWithData);

  readonly dataByDate$: Observable<DailyUserData> = this.store.select(selectDataByDate);
  readonly loadingDataByDate$: Observable<boolean> = this.store.select(selectLoadingDataByDate);

  readonly userData$: Observable<UserData> = this.store.select(selectUserData);
  readonly loadingUserData$: Observable<boolean> = this.store.select(selectLoadingUserData);

  readonly additionalUserInfo$: Observable<AdditionalUserInfo> = this.store.select(selectAdditionalUserInfo);
  readonly additionalUserInfoLoaded$: Observable<boolean> = this.store.select(selectAdditionalUserInfoLoaded);

  constructor(private store: Store) {}

  canActivate() {
    return this.loadingUserData$.pipe(
      tap((loading) => {
        if (!loading) {
          this.store.dispatch(UserDataActions.loadUserData());
        }
      })
    );
  }

  save(data: DailyUserData) {
    this.store.dispatch(UserDataActions.save({ data: data }));
  }

  loadDatesWithData(year?: number, month?: number) {
    this.store.dispatch(
      UserDataActions.loadDatesWithData({
        year: year ?? new Date().getFullYear(),
        month: (month ?? new Date().getMonth()) + 1
      })
    );
  }

  loadDataByDate(date: Date) {
    this.store.dispatch(
      UserDataActions.loadDataByDate({
        date: date
      })
    );
  }

  getAdditionalUserInfo() {
    this.store.dispatch(UserDataActions.getAdditionalUserInfo());
  }

  addAdditionalUserDataInfo(height: number, birthday: Date) {
    this.store.dispatch(
      UserDataActions.addAdditionalUserInfo({
        height: height,
        birthday: birthday
      })
    );
  }
}
