import { CustomerWeight, CustomerSteps, CustomerNutrition } from 'src/app/shared/models/customer-data.model';

export const FEATURE_NAME = 'user-data';

export interface UserDataState {
  error?: Error;
  loadingDatesWithData?: boolean;
  loadedDatesWithData?: boolean;
  errorDatesWithData?: Error;
  datesWithData: string[];
  loadingDataByDate?: boolean;
  loadedDataByDate?: boolean;
  dataByDate?: DailyUserData;
  errorDataByDate?: Error;
  userData?: UserData;
  loadingUserData?: boolean;
  additionalUserInfo?: AdditionalUserInfo;
  loadingAdditionalUserInfo?: boolean;
  loadedAdditionalUserInfo?: boolean;
}

export interface DailyUserData {
  date: string;
  steps: number;
  trained: boolean;
  weight: number;
  nutrition: {
    cheatDay: boolean;
    carbohydrates?: number;
    proteins?: number;
    fats?: number;
    calories?: number;
  };
}

export interface UserData {
  customerWeights?: CustomerWeight[];
  customerSteps?: CustomerSteps[];
  customerNutrition?: CustomerNutrition[];
}

export interface AdditionalUserInfo {
  id: number;
  height: number;
  birthday: Date;
}
