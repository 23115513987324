export interface AuthState {
  user?: User;
  roles?: UserRole[];
  rolesLoading: boolean;
  rolesLoaded: boolean;
}

export interface User {
  uid: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  photoURL: string;
  providerId: string;
}

export enum UserRole {
  PT = 'PT',
  Customer = 'customer'
}
