import { Injectable } from '@angular/core';
import { UserRole } from './auth.model';
import { select, Store } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { selectCurrentRole, selectHasMultipleRoles, selectPhotoURL, selectUser } from './auth.selectors';
import firebase from 'firebase/compat';
import { mapFirebaseUser } from './auth.mapper';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user$ = this.store.pipe(select(selectUser));
  photoURL$ = this.store.pipe(select(selectPhotoURL));
  role$ = this.store.pipe(select(selectCurrentRole));
  hasMultipleRoles$ = this.store.pipe(select(selectHasMultipleRoles));

  constructor(private store: Store) {}

  signInWithGoogle() {
    this.store.dispatch(AuthActions.signInWithGoogle());
  }

  signIn(email: string, password: string) {
    this.store.dispatch(AuthActions.signInWithEmailAndPassword({ email: email, password: password }));
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    this.store.dispatch(AuthActions.createUserWithEmailAndPassword({ email: email, password: password }));
  }

  signOut(redirect: boolean = true) {
    this.store.dispatch(AuthActions.signOut({ redirect: redirect }));
  }

  completeProfile(displayName: string, roles: UserRole[]) {
    this.store.dispatch(AuthActions.completeProfile({ displayName: displayName, roles: roles }));
  }

  updateDisplayName(displayName: string) {
    this.store.dispatch(AuthActions.updateDisplayName({ displayName: displayName }));
  }

  updatePhoto(photo: File) {
    this.store.dispatch(AuthActions.updatePhoto({ photo: photo }));
  }

  updatePassowrd(oldPassword: string, newpassword: string) {
    this.store.dispatch(AuthActions.updatePassword({ oldPassword: oldPassword, newpassword: newpassword }));
  }

  stateChanged(user: firebase.User) {
    this.store.dispatch(AuthActions.authStateChanged({ user: user ? mapFirebaseUser(user) : undefined }));
  }

  selectRole(role: UserRole) {
    this.store.dispatch(AuthActions.selectRole({ role: role }));
  }
}
