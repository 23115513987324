import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.model';
import { AuthActions } from './auth.actions';

export const initialState: AuthState = {
  user: undefined,
  roles: undefined,
  rolesLoaded: false,
  rolesLoading: false
};

export const authReducer = (state: AuthState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(AuthActions.authStateChanged, (state, { user }) => {
    return {
      ...state,
      user: user
    };
  }),
  on(AuthActions.signOutSuccess, () => initialState),
  on(AuthActions.loadRoles, (state) => {
    return {
      ...state,
      rolesLoading: true
    };
  }),
  on(AuthActions.loadRolesSuccess, (state, { roles: role }) => {
    return {
      ...state,
      roles: role,
      rolesLoading: false,
      rolesLoaded: true
    };
  }),
  on(AuthActions.completeProfileSuccess, (state, { displayName, roles }) => {
    return {
      ...state,
      roles: roles,
      user: {
        ...state.user,
        displayName: displayName
      }
    };
  }),
  on(AuthActions.updateDisplayNameSuccess, (state, { displayName }) => {
    return {
      ...state,
      user: {
        ...state.user,
        displayName: displayName
      }
    };
  }),
  on(AuthActions.updatePhotoSuccess, (state, { photo }) => {
    return {
      ...state,
      user: {
        ...state.user,
        photoURL: photo
      }
    };
  })
);
