/**
 * Return a string from date with the format `yyyy-MM-dd`.
 * @param date The input date.
 * @returns A string with the format `yyyy-MM-dd`.
 */
export const format = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();

  return `${year}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`;
};

/**
 * @param date The input date.
 * @param months The number of months to subtract.
 * @returns A new date.
 */
export const subtractMonths = (date: Date, months: number): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  return result;
};

/**
 * @param date The input date.
 * @param months The number of months to add.
 * @returns A new date.
 */
export const addMonths = (date: Date, months: number): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};
