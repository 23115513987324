import { Action, createReducer, on } from '@ngrx/store';
import { UserDataState } from './user-data.model';
import { UserDataActions } from './user-data.actions';

export const initialState: UserDataState = {
  error: undefined,
  loadingDatesWithData: false,
  loadedDatesWithData: undefined,
  errorDatesWithData: undefined,
  datesWithData: [],
  loadingDataByDate: false,
  loadedDataByDate: undefined,
  errorDataByDate: undefined,
  dataByDate: undefined,
  additionalUserInfo: undefined,
  loadingAdditionalUserInfo: false,
  loadedAdditionalUserInfo: undefined
};

export const userDataReducer = (state: UserDataState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(UserDataActions.loadDatesWithData, (state) => ({
    ...state,
    loadingDatesWithData: true,
    loadedDatesWithData: undefined,
    datesWithData: undefined,
    errorDatesWithData: undefined
  })),
  on(UserDataActions.loadDatesWithDataSuccess, (state, { dates }) => {
    return {
      ...state,
      loadingDatesWithData: false,
      loadedDatesWithData: true,
      datesWithData: dates
    };
  }),
  on(UserDataActions.loadDatesWithDataError, (state, { error }) => ({
    ...state,
    loadingDataByDate: false,
    loadedDataByDate: false,
    errorDataByDate: error
  })),
  on(UserDataActions.loadDataByDate, (state) => ({
    ...state,
    loadingDataByDate: true,
    loadedDataByDate: undefined,
    dataByDate: undefined,
    errorDataByDate: undefined
  })),
  on(UserDataActions.loadDataByDateSuccess, (state, { data }) => {
    return {
      ...state,
      loadingDataByDate: false,
      loadedDataByDate: true,
      dataByDate: data
    };
  }),
  on(UserDataActions.loadDataByDateError, (state, { error }) => ({
    ...state,
    loadingDataByDate: false,
    loadedDataByDate: false,
    errorDataByDate: error
  })),
  on(UserDataActions.loadUserData, (state) => ({
    ...state,
    userData: undefined,
    loadingUserData: true
  })),
  on(UserDataActions.loadUserDataSuccess, (state, { userData }) => ({
    ...state,
    userData: userData,
    loadingUserData: false
  })),
  on(UserDataActions.getAdditionalUserInfo, (state) => ({
    ...state,
    additionalUserInfo: undefined,
    loadingAdditionalUserInfo: true
  })),
  on(UserDataActions.getAdditionalUserInfoSuccess, (state, { additionalUserInfo }) => ({
    ...state,
    additionalUserInfo: additionalUserInfo,
    loadingAdditionalUserInfo: false,
    loadedAdditionalUserInfo: true
  }))
);
