import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, of, tap, first } from 'rxjs';
import { UserDataDataService } from './user-data-data.service';
import { UserDataActions } from './user-data.actions';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/notification/notification.service';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private userDataDataService: UserDataDataService,
    private dialog: MatDialog
  ) {}

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.save),
      exhaustMap((action) =>
        this.userDataDataService.save(action.data).pipe(
          first(),
          map(() => UserDataActions.saveSuccess()),
          catchError((error) => of(UserDataActions.saveError({ error: error })))
        )
      )
    )
  );

  saveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.saveSuccess),
        tap(() => {
          this.dialog.closeAll();
          this.notificationService.success('Dati aggiornati correttamente!');
        })
      ),
    { dispatch: false }
  );

  saveError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.saveError),
        tap(() => this.notificationService.error('Ops. Qualcosa è andato storto durante il salvataggio dei dati.'))
      ),
    { dispatch: false }
  );

  loadDatesWithData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadDatesWithData),
      exhaustMap((action) =>
        this.userDataDataService.loadDatesWithData(action.year, action.month).pipe(
          first(),
          map((dates) => UserDataActions.loadDatesWithDataSuccess({ dates: dates })),
          catchError((error) => of(UserDataActions.loadDatesWithDataError({ error: error })))
        )
      )
    )
  );

  loadDataByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadDataByDate),
      exhaustMap((action) =>
        this.userDataDataService.loadDataByDate(action.date).pipe(
          first(),
          map((data) => UserDataActions.loadDataByDateSuccess({ data: data })),
          catchError((error) => of(UserDataActions.loadDataByDateError({ error: error })))
        )
      )
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.loadUserData),
      exhaustMap(() =>
        this.userDataDataService.loadUserData().pipe(
          first(),
          map((userData) => UserDataActions.loadUserDataSuccess({ userData: userData })),
          catchError((error) => of(UserDataActions.loadUserDataError({ error: error })))
        )
      )
    )
  );

  getAdditionalUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.getAdditionalUserInfo),
      exhaustMap(() =>
        this.userDataDataService.getAdditionalUserInfo().pipe(
          map(
            (additionalUserInfo) => UserDataActions.getAdditionalUserInfoSuccess({ additionalUserInfo: additionalUserInfo }),
            catchError((error) => of(UserDataActions.getAdditionalUserInfoError({ error: error })))
          )
        )
      )
    )
  );

  addAdditionalUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDataActions.addAdditionalUserInfo),
      exhaustMap((action) =>
        this.userDataDataService.addAdditionalUserInfo(action.height, action.birthday).pipe(
          map(
            (additionalUserInfo) => UserDataActions.addAdditionalUserInfoSuccess({ additionalUserInfo: additionalUserInfo }),
            catchError((error) => of(UserDataActions.addAdditionalUserInfoError({ error: error })))
          )
        )
      )
    )
  );

  addAdditionalUserInfoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.addAdditionalUserInfoSuccess),
        tap(() => {
          this.dialog.closeAll();
          // this.notificationService.success('Profilo aggiornato con successo.');
        })
      ),
    { dispatch: false }
  );

  addAdditionalUserInfoError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataActions.addAdditionalUserInfoError),
        tap((error) => this.notificationService.error(error.error.message))
      ),
    { dispatch: false }
  );
}
