import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PtcButtonToggleGroupOption } from '../ptc-button-toggle-group/ptc-button-toggle-group.model';

@Component({
  selector: 'app-ptc-page-header',
  templateUrl: './ptc-page-header.component.html',
  styleUrl: './ptc-page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PtcPageHeaderComponent {
  @Input({ required: true }) title: string;
  @Input() buttonConfig?: {
    label?: string;
    icon?: string;
    hint?: string;
  };
  @Input() backButton?: {
    defaultLink?: string;
    link?: string;
    disabled?: boolean;
  };

  @Input() buttonToggleGroupConfig?: {
    options: PtcButtonToggleGroupOption<unknown>[];
    defaultOption;
  };

  @Output() buttonClicked = new EventEmitter<void>();

  @Output() optionChanged = new EventEmitter<PtcButtonToggleGroupOption<unknown>>();

  onClick() {
    this.buttonClicked.emit();
  }

  onOptionChanged(option: PtcButtonToggleGroupOption<unknown>) {
    this.optionChanged.emit(option);
  }
}
