import firebase from 'firebase/compat';
import { User } from './auth.model';

export const mapFirebaseUser = (user: firebase.User): User => {
  return {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    photoURL: user.photoURL,
    providerId: user.providerData[0]?.providerId
  };
};
