@if (backButton) {
  <app-ptc-back-button-container [link]="backButton.link" [defaultLink]="backButton.defaultLink" [disabled]="backButton.disabled" />
}
<div class="row">
  <div class="col">
    <h1>{{ title }}</h1>
  </div>
  <div class="col-auto">
    @if (buttonConfig) {
      <button mat-button class="bg-light-primary text-primary f-w-600" [attr.data-hint]="buttonConfig.hint" (click)="onClick()">
        @if (buttonConfig.icon) {
          <mat-icon class="d-flex align-items-center"><i-tabler [name]="buttonConfig.icon" class="icon-18 d-flex"></i-tabler></mat-icon>
        }
        @if (buttonConfig.label) {
          {{ buttonConfig.label }}
        }
      </button>
    }
    @if (buttonToggleGroupConfig) {
      <app-ptc-button-toggle-group
        [options]="buttonToggleGroupConfig.options"
        [selectedOption]="buttonToggleGroupConfig.defaultOption"
        (selectedOptionChange)="onOptionChanged($event)"
      ></app-ptc-button-toggle-group>
    }
  </div>
</div>
